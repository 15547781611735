@use '@angular/material' as mat

@include mat.core
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.m2-define-palette(mat.$m2-grey-palette)
$accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, A200, A100, A400)

// The warn palette is optional (defaults to red).
$warn: mat.m2-define-palette(mat.$m2-red-palette)

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.m2-define-light-theme((color:(primary: $primary, accent:$accent, warn: $warn)))

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme)

@charset 'UTF-8'
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')
@import 'bootstrap/dist/css/bootstrap.min.css'
body
  overflow-x: hidden
  margin: 0
  padding: 0
  font-family: Lato
  min-height: 100vh
  min-height: 100svh

